import { useEffect, useState } from "react";
import {
  Tree,
  handldOnClick,
  ImpressionEvent,
  handldOnImpression,
  store,
  ClickEvent,
  Loading,
} from "bxl-legocore";
import { DownloadBar, ConfigProvider, Cart } from "lego-components";
import {
  MiniBridge,
  bxlNavigation,
  isMiniProgram,
  DeeplinkParser,
} from "bxl-miniParse";
import utils from 'bxl-utils'
import bxlbridge from 'bxl-bridge'
import { registerDeeplinkParser } from "./api";
import tracking, {
  getLocationByPid,
  getTrackWithComponentType,
  setImpression,
} from "./utils/tracking";
import { components,  locale, type ComponentTypes } from "./lib";
import { usePageConfig, useAppConfig, useContent, useCache, useCart, usePromiseConcurrencyQueues  } from './hooks'
import {
  getFetcher,
  setProductData,
  setCombinationList,
  setIframeData,
} from "./utils/update";
import {
  BASE_PATH,
  DOWNLOAD_LINK,
  DOWNLOAD_PAGE_PREFIX,
  BXL_META_PREFIX,
  PAGE_LOCATION,
  BYH5,
  renderToComponent,
} from "./constant";
import SEO from './components/seo'
import {
  isBieyangApp,
  generateUtmInfoByLocation,
  isLogin,
  nativeNavigator,
  initDevice,
  geNeedUpdatetDescendants,
} from "./utils";
import {
  initMiniProgram,
  getMetaInfo,
  initAppSetting,
} from "./utils/bridge";
import { share } from "./utils/share";
import { createPromiseWithoutReject } from './utils/promise-concurrency-queues'
import type { AxiosResponse } from 'axios'
getMetaInfo()
registerDeeplinkParser(isLogin);
initMiniProgram()
initDevice()

type ReturnPromiseType<T> = T extends ((...params: any) => Promise<infer K>) ? K : never

type Pool = ReturnPromiseType<
  ReturnType<typeof createPromiseWithoutReject<AxiosResponse, ImpressionEvent>>
>

function App() {
  const [isMini, setIsMini] = useState(false);

  const [content, pending] = useContent(
    process.env.NODE_ENV === "development"
      ? "https://baleen-cdn-g.bybieyang.com/lego/pages/benning_20240219_viplimitedsale/index.html"
      : undefined
  );

  const pool = usePromiseConcurrencyQueues<Pool>((res) => {
    res.forEach(({ data, error, event }) => {
      if (error || !data?.data) {
        return setCache(event.config.id, false);
      }
      switch(event.config.render as ComponentTypes) {
        case "ProductList":
           setProductData(event, data.data)
           break
        case "CombinationList":
           setCombinationList(event, data.data)
           break;
        case "Iframe":
           setIframeData(event, data.data)
           break;
      }
    });
  });

  useEffect(() => {
    isMiniProgram().then((res: any) => {
      if (res) {
        setIsMini(true);
      }
    });
  }, []);

  useEffect(() => {
    if (content) {
      store.setState(content, true);
      const config = content?.root?.config;
      const pageId = config?.pageId;
      if (isBieyangApp) {
        initAppSetting(config);
      }
      isMiniProgram().then((res: any) => {
        if (res) {
          MiniBridge.invoke("shareMsg", {
            title: config.shareTit,
            desc: config.shareDes,
            imageUrl: config.shareImg,
          });
        }
      });
      share(config);
      tracking.send({
        webPageOpen: {
          pageName: "legoPage",
          id: pageId || "",
          url: window.location.href,
          viewType: PAGE_LOCATION,
          previousPage:
            localStorage.getItem(`${BXL_META_PREFIX}previousPage`) || "",
        },
      });
    }
  }, [content]);

  const pageConfig = usePageConfig();

  const appConfig = useAppConfig(
    !isBieyangApp && !isMini && pageConfig?.showDownload
  );

  const cart = useCart(pageConfig?.showToCartOrder);

  const { setCache, hasCache } = useCache<string, boolean>();

  const getWebComponentParams = (params: Record<string, any> = {}) => {
    return {
      webComponentClick: {
        pageId: pageConfig?.pageId,
        url: window.location.href,
        pageName: "legoPage",
        viewType: PAGE_LOCATION,
        previousPage:
          localStorage.getItem(`${BXL_META_PREFIX}previousPage`) || "",
        ...params,
      },
    };
  }
  
  const onClickAtH5: handldOnClick = (e) => {
    const { deeplink, link, render, componentId, productId } = e
    let elPath = "";
    switch (render) {
      case "ImageBox":
      case "MixedBlocks":
      case "CustomTitle":
      case "NoticeBar":
      case "Block":
        if (link) {
          elPath = link;
        } else {
          elPath = deeplink ? DeeplinkParser.getParseLink(deeplink) : "";
        }
        if (elPath.indexOf("http") > -1) {
          let eqlink = generateUtmInfoByLocation(pageConfig);
          if (elPath.indexOf("?") > -1) {
            elPath =
              elPath.split("?")[0] + "?" + eqlink + "&" + elPath.slice(elPath.indexOf("?") + 1);
          } else {
            elPath = elPath + "?" + eqlink;
          }
        }
        break;
      case "ProductList":
        elPath = pageConfig?.productLink
          ? pageConfig?.productLink + "?"
          : `https://baleen-cdn-g.bybieyang.com/static/html/byh5/index.html?`;
        let eqlink = generateUtmInfoByLocation(pageConfig);
        elPath = elPath + eqlink + `&/#/product/${productId}`;
        break;
      case "SearchBar":
        let slink = generateUtmInfoByLocation(pageConfig);
        elPath = `${
          pageConfig?.domain || BASE_PATH
        }/lego/search/index.html?${slink}`;
        break;
    }
    tracking.send(
      getWebComponentParams({ component: renderToComponent[render], componentId })
    );
    handleNavigator(elPath, e);
  };

  const onClickAtApp: handldOnClick = (e) => {
    const {
      deeplink,
      link,
      render,
      componentId,
      productId,
      combinationId,
      combinationDeeplink,
    } = e;
    switch (render) {
      case "ImageBox":
      case "MixedBlocks":
      case "CustomTitle":
      case "NoticeBar":
      case "Block":
        if (!(deeplink || link)) return;
        tracking.send(
          getWebComponentParams({
            component: renderToComponent[render],
            componentId,
          })
        );
        let arrState = JSON.stringify({
          nodes: getLocationByPid(
            componentId || "",
            getTrackWithComponentType(componentId, {
              deeplink,
              link,
              productId,
              combinationId,
            }),
            e.getState
          ),
        });
        bxlbridge.invoke("recordPageState", arrState, (result: string) => {
          try {
            console.log(result);
          } catch (e) {
            console.log(e, result);
          }
        });
        nativeNavigator(deeplink || link);
        break;
      case "ProductList":
        let state = JSON.stringify({
          nodes: getLocationByPid(
            componentId || "",
            getTrackWithComponentType(componentId, {
              deeplink,
              link,
              productId,
              combinationId,
            }),
            e.getState
          ),
        });
        bxlbridge.invoke("recordPageState", state, (result: string) => {
          try {
            console.log(result);
          } catch (e) {
            console.log(e, result);
          }
        });
        tracking.send(
          getWebComponentParams({
            component: renderToComponent[render],
            componentId,
          })
        );
        window.location.href = utils.uri.buildBieyangDeeplink("pdp", {
          productId: productId,
        });
        break;
      case "VideoBox":
        tracking.send(
          getWebComponentParams({
            component: renderToComponent[render],
            componentId,
          })
        );
        break;
      case "SearchBar":
        tracking.send(
          getWebComponentParams({
            component: renderToComponent[render],
            componentId,
          })
        );
        window.location.href = "bieyang:///plp";
        break;
      case "CombinationList":
        let stateString = JSON.stringify({
          nodes: getLocationByPid(
            componentId || "",
            getTrackWithComponentType(componentId, {
              deeplink,
              link,
              productId,
              combinationId,
            }),
            e.getState
          ),
        });
        bxlbridge.invoke("recordPageState", stateString, (result:Record<string, any>) => {
          try {
            console.log(result);
          } catch (e) {
            console.log(e, result);
          }
        });
        tracking.send(
          getWebComponentParams({
            component: renderToComponent[render],
            componentId,
          })
        );
        nativeNavigator(combinationDeeplink);
        break;
      default:
        break;
    }
  };

  const handleClick: handldOnClick = (e) => {
    isBieyangApp ? onClickAtApp(e) : onClickAtH5(e)
  };
  
  const onImpression: handldOnImpression = (visible, e) => {
    // todo 支持优先级 对于类似于会场, 提高当前会场更新优先级
    if (visible && !hasCache(e.config.id)) {
      setCache(e.config.id, true);
      const render = e.config.render as ComponentTypes
      switch(render) {
        case "ProductList":
        case "CombinationList":
        case "Iframe":
          return pool.push(createPromiseWithoutReject(getFetcher(render), e));
        case "AnchorTabs":
          const descendants = geNeedUpdatetDescendants(e.config.id, e.getState(), ["ProductList", "CombinationList"])
          return pool.push(
            ...descendants.reduce((init, config) => {
              if(!hasCache(config.id)) {
                setCache(config.id, true);
                init.push(createPromiseWithoutReject(getFetcher(config.render), {...e, config}))
              }
              return init
            }, [] as ReturnType<typeof createPromiseWithoutReject<AxiosResponse, ImpressionEvent>>[])
          );
      }
    }
  };

  const handleNavigator = (
    href: string,
    e: ClickEvent
  ) => {
    if (!href) {
      return;
    }
    if (isMini) {
      const { deeplink, link, getState, productId, combinationId, componentId } = e
      const list = getLocationByPid(
        componentId,
        getTrackWithComponentType(componentId, {
          deeplink,
          link,
          productId,
          combinationId,
        }),
        getState
      );
      MiniBridge.invoke("sendPageLocation", list, () => {});
      setTimeout(() => {
        if (href.startsWith("/pages")) {
          bxlNavigation.navigateTo({
            url: href,
          });
        } else {
          let url = encodeURIComponent(href);
          bxlNavigation.navigateTo({
            url: `/pages/web/webview?url=${url}`,
          });
        }
      }, 200);
    } else {
      window.location.href = href;
    }
  };

  const onCallApp = () => {
    const componentId = "downloadBar";
    const bieyang = encodeURIComponent(
      `bieyang:///wvp?link=${utils.uri.encodeBase64(window.location.href)}`
    );
    const downloadLink = pageConfig?.downloadLink
      ? pageConfig?.downloadLink
      : DOWNLOAD_LINK;
    const link = `${DOWNLOAD_PAGE_PREFIX}?bieyang=${bieyang}&download=${encodeURIComponent(
      downloadLink
    )}`;
    tracking.send(
      getWebComponentParams({ component: "downloadBar_download", componentId })
    );
    handleNavigator(link, {
      getState: store.getState,
      componentId,
    } as ClickEvent);
  }

  const onClickCart = (type: "cart" | "order-list") => {
    let slink = generateUtmInfoByLocation(pageConfig);
    if (utils.platform.isBieyangApp()) {
      if (type === "cart") {
        window.location.href = `bieyang:///scp`;
      } else if (type === "order-list") {
        window.location.href = `bieyang:///order_list`;
      }
    } else if (isMini) {
      if (type === "cart") {
        bxlNavigation.switchTab({
          url: "/pages/cart/cart",
        });
      } else if (type === "order-list") {
        bxlNavigation.navigateTo({
          url: "/pages/order/order-list/index",
        });
      }
    } else {
      const channel = pageConfig?.channel;
      if (channel) {
        window.location.href = `https://${channel}.santasmartnetwork.com/ssh5/index.html?${slink}#/${type}`;
      } else {
        window.location.href = `${BYH5}?${slink}#/${type}`;
      }
    }
  };

  useEffect(() => {
    let impression: ReturnType<typeof setImpression> | null;
    if(pageConfig) {
      impression = setImpression(pageConfig?.pageId);
    }
    return () => {
      impression?.detach();
    };
  }, [pageConfig])

  return (
    <>
      <SEO
        title={pageConfig?.title}
        keywords={pageConfig?.keywords}
        description={pageConfig?.description}
      />
      {pending && <Loading classsName="page-loading" />}
      <ConfigProvider locale={locale}>
        <DownloadBar
          visible={!isBieyangApp && !isMini && pageConfig?.showDownload}
          amount={appConfig.priceWithCurrencyText}
          onCallApp={onCallApp}
        />
        <Cart
          visible={pageConfig?.showToCartOrder}
          count={cart.count}
          onClickCart={() => onClickCart("cart")}
          onClickOrderList={() => onClickCart("order-list")}
        />
        <Tree
          onClick={handleClick}
          components={components}
          onImpression={onImpression}
        />
      </ConfigProvider>
    </>
  );
}

export default App
