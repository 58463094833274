import { BxlAnalytics } from 'bxl-analytics';
import bxlbridge from 'bxl-bridge';
import utils from 'bxl-utils';
import md5 from "blueimp-md5";
import { Impression } from "bxl-analytics";
import { platform as getPlatform, MiniBridge, PLATFORM } from 'bxl-miniParse';
import type { ImpressionEvent } from 'bxl-legocore'

import {
  PAGE_LOCATION,
  BXL_USER_ID,
  BXL_USER_KEY,
  BXL_META_device_id,
  BXL_GUEST_ID,
  BXL_META_PREFIX,
} from "../constant";

const tracker = new BxlAnalytics();
// if (process.env.NODE_ENV === 'development') {
//   tracker.useDogfoodApi(true);
// }

let meta: Record<string, any> = {
  platform: 'H5_LegoArch_',
};

bxlbridge.invoke('getSession', {}, (session: Record<string, any>) => {
  try {
    session = bxlbridge.parseBridgeParams(session);
    localStorage.setItem(BXL_USER_ID, session["userId"] || "");
    localStorage.setItem(BXL_USER_KEY, session["userKey"] || "");
    meta = {
      ...meta,
      ...session,
    };
  } catch (e) {
    console.error(e);
  }
});

bxlbridge.invoke('getMetaInfo', {}, (result: string) => {
  // 通过 bridge 获取真正的用户数据
  try {
    meta = {
      ...meta,
      ...bxlbridge.parseBridgeParams(result),
    };
  } catch (e) {
    console.error(e, result);
  }
});

let superProperties: Record<string, any> = {
  appVersion: () => meta['app_version'] || '1.0.0',
  guestId: () => {
    return new Promise((res) => {
        getPlatform((platform: any) => {
            if(platform === PLATFORM.IN_APP) {
                bxlbridge.invoke('getMetaInfo', {}, (result: any) => {
                    try {
                        const guestId = result.guest_id;
                        guestId && localStorage.setItem(BXL_GUEST_ID, guestId || '');
                        if(result && guestId) {
                            res(guestId)
                        } else {
                            res(localStorage.getItem(BXL_GUEST_ID) || '');
                        }
                    } catch (e) {}
                  });
            } else if(platform === PLATFORM.WECHAT_MINI || platform === PLATFORM.ALIPAY_MINI) {
                MiniBridge.invoke('getSession', {}, (resp: any) => {
                    const { guestId } = resp;
                    guestId && localStorage.setItem(BXL_GUEST_ID, guestId || '');
                    if(resp && guestId) {
                        res(guestId)
                    } else {
                        res(localStorage.getItem(BXL_GUEST_ID) || '');
                    }
                })
            } else {
                res(localStorage.getItem(BXL_GUEST_ID) || '');
            }
        });
    })
  },
  platform: () =>
    meta && meta['platform'] ? meta['platform'] + 'web' : 'web',
  sessionStart: () =>
    meta && +meta['session_start'] ? +meta['session_start'] : 0,
  userId: () =>
    (meta && meta['userId']) || localStorage.getItem(BXL_USER_ID) || '',
  loggedIn: () => (meta['userId'] && meta['userKey'] ? true : false),
};

if (utils.platform.isBieyangApp()) {
superProperties['deviceId'] = () => (meta && meta['device_id']) || localStorage.getItem(BXL_META_device_id) || ''
}  

tracker.registerSuperProperties(superProperties);

export const ComponentForTracing = {
  AnchorTabs: {
    locationV2: "H5_LG_AT",
  },
  ProductList: {
    locationV2: "H5_LG_PL",
  },
  ImageBox: {
    locationV2: "H5_LG_IB",
  },
  CombinationList: {
    locationV2: "H5_LG_CL",
  },
};

export  const getLocationByPid = (
  id: string | undefined,
  list: Array<Record<string, any>> = [], 
  getContent: ImpressionEvent['getState']
) => {
    let trackerList = [...list];
    let key = (id?.split("_")[0] || "") as keyof typeof ComponentForTracing;
    const content = getContent()
    trackerList.unshift({
      keyinfo: id,
      ...ComponentForTracing[key],
    });
    //大会场组件 返回根组件名
    let conference = content?.root?.childrens?.find(
      (item) => item.indexOf("Conference") !== -1
    );
    if (conference) {
      trackerList.unshift(
        {
          keyInfo: `eventId:${content?.root?.config?.pageId}`,
          locationV2: PAGE_LOCATION,
        },
        {
          locationV2: "DL_H5",
        }
      );
    }
    let pid = content?.id?.pid; 
    if (!pid) return trackerList;
    if (pid !== "root" && id !== pid && id !== "root") {
      trackerList = getLocationByPid(pid, trackerList, getContent);
    } else {
      trackerList.unshift(
        {
          keyInfo: `eventId:${content["root"]["config"]["pageId"]}`,
          locationV2: PAGE_LOCATION,
        },
        {
          locationV2: "DL_H5",
        }
      );
    }
    return trackerList;
  };

export const getTrackWithComponentType = (
  type: string,
  option: {
    deeplink: string | undefined | null;
    link: string | undefined | null;
    productId: string | undefined | null;
    combinationId: string | undefined | null;
  }
) => {
  const { deeplink, link, productId, combinationId } = option || {};
  switch (type) {
    case "NoticeBar":
      return [
        {
          locationV2: "H5_LG_NB",
        },
      ];
    case "Block":
      return [
        {
          locationV2: "H5_LG_BL",
        },
      ];
    case "Image":
      return [
        {
          keyInfo: `lgim:${getImgId(deeplink, link)}`,
          locationV2: "H5_LG_IM",
        },
      ];
    case "MixedBlock":
      return [
        {
          locationV2: "H5_LG_MB",
        },
      ];
    case "CustomTitle":
      return [
        {
          locationV2: "H5_LG_TL",
        },
      ];
    case "ProductList":
      return [
        {
          keyInfo: `pro:${productId}`,
          locationV2: "H5_LG_PI",
        },
      ];
    case "CombinationList":
      return [
        {
          keyInfo: `cop:${combinationId}`,
          locationV2: "H5_LG_CL",
        },
      ];
    default:
      return [];
  }
};


export const setImpression = (pageId: string) => {
  const elimpression = new Impression({
    debounce: 400,
    // element: "#root",
  });
  elimpression.attach();
  elimpression.all(
    [
      ".by-lego-column-product",
      ".by-lego-row-product",
      ".by-lego-image-box",
      ".by-lego-video-box",
    ],
    (nodes: {key?: string, node?: HTMLElement}[]) => {
      let arr: any[] = [];
      nodes.forEach((item) => {
        arr.push({
          id: item.node?.dataset.id || "",
          pid: item.node?.dataset?.componentid || "",
          productId: item.node?.dataset?.productid || "",
          index: parseInt(item.node?.dataset.index || "0") * 1,
        });
      });
      tracker.send({
        webComponentImpression: {
          pageId: pageId,
          url: window.location.href,
          webImpressionItem: arr,
          pageName: "legoPage",
          viewType: PAGE_LOCATION,
          previousPage:
            localStorage.getItem(`${BXL_META_PREFIX}previousPage`) || "",
        },
      });
    }
  );
  return elimpression;
};

// 通过base64获取图片Id
function getImgId(deeplink: string | undefined | null, link?: string | undefined | null) {
  let path = deeplink || link || '';
  return md5(path).substring(0, 6);
};



export default tracker;
