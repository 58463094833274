import React from 'react'
import ReactDOM from 'react-dom/client'
import "intersection-observer";
import './styles/index.scss'
import App from './App'
import 'amfe-flexible/index.js'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://3b74e1a214ac42e29fed26b37169060c@sentry.bybieyang.com/3",
    release: `lego-arch@${process.env.npm_package_version}`,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<>An error has occured</>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
