import { useRef, useCallback } from 'react'

export type Cache<K, T> = ReturnType<typeof useCache<K, T>>

export default function useCache< K = string, T = any>() {
  const cacheRef = useRef(new Map<K, T>())
  const setCache = useCallback((key: K, value: T) => {
    return cacheRef.current.set(key, value)
  }, [])
  const hasCache = useCallback((key: K) => {
    return cacheRef.current.has(key);
  }, []);
  const deleteCache = useCallback((key: K) => {
    return cacheRef.current.delete(key);
  }, []);

  const getCache = useCallback((key: K) => {
    return cacheRef.current.get(key);
  }, []);

  return {
    cache: cacheRef,
    setCache,
    hasCache,
    deleteCache,
    getCache,
  };
}