import React, { useEffect, useRef } from 'react'
interface IProps {
  id: string
  duration: number
  onClose: (id: string, isShowMask: boolean) => void
  isShowMask: boolean
  text?: string
}

const ToastItem: React.FC<IProps> = (props: IProps) => {
  const { text, id, duration, onClose, isShowMask } = props;
  const propsRef = useRef(props)
  const timerRef: any = useRef();
  useEffect(() => {
    propsRef.current = props;
    timerRef.current = setTimeout(() => {
      if (onClose) {
        onClose(id, isShowMask);
      }
    }, duration * 1000)
    return () => {
      clearTimeout(timerRef.current)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="toast-item">
      {text}
    </div>
  )
}

export default ToastItem