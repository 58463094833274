import type { CSSProperties } from 'react'
import utils from "bxl-utils";
import type { Content, INode } from 'bxl-legocore'
import { BXL_USER_ID, BXL_USER_KEY, BXL_GUEST_ID } from "../constant";
import type { ProductListProps } from 'lego-components'
export const isObject = <T extends {}>(target: any): target is T =>
  Reflect.toString.call(target) === "[object Object]";

export const isFunction = <T extends Function>(target: any): target is T =>
  typeof target === "function";

export const compose = <T = any>(
  ...funcs: ((context: T, next: Function) => void)[]
) => {
  const list = funcs.filter((func) => isFunction(func));
  return function (context: T, next?: (context: T) => void) {
    let index = -1;
    return dispatch(0);
    function dispatch<K = any>(i: number): K | void {
      if (i <= index) {
        return;
      }
      index = i;
      let handler: Function | undefined = i === list.length ? next : list[i];
      try {
        return handler?.(context, dispatch.bind(null, i + 1));
      } catch (err) {}
    }
  };
};

export const MapNumber = <T>(
  count: number | undefined,
  callback: (item: number) => T
) => {
  const list: T[] = [];
  if (typeof count !== "number" || count === 0) {
    return list;
  }
  for (let i = 0; i < count; i++) {
    list.push(callback(i));
  }
  return list;
};

export const isBieyangApp = utils.platform.isBieyangApp();


export const generateUtmInfoByLocation = (pageConfig?: Record<string, any>) => {
  let queries = utils.uri.parseUrlParams(window.location.href);
  return utils.uri.stringify({
    utm_source: queries.utm_source || "lego",
    utm_medium: queries.utm_medium,
    utm_campaign: queries.utm_campaign || "lego",
    utm_term: queries.utm_term || pageConfig?.pageId,
    utm_content: queries.utm_content,
    channel: pageConfig?.channel,
  });
};

export const isLogin = () => {
  return (
    localStorage.getItem(BXL_USER_ID) && localStorage.getItem(BXL_USER_KEY)
  );
};

export const nativeNavigator = (link?: string | null) => {
  link && (window.location.href = link);
}

export function generateUUID () {
  let d = new Date().getTime();
  //Time in microseconds since page-load or 0 if unsupported
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    //random number between 0 and 16
    let r = Math.random() * 16;
    //Use timestamp until depleted
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
      //Use microseconds since page-load if supported
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const initDevice = () => {
  if (utils.platform.isBieyangApp()) return;
  let guestId = localStorage.getItem(BXL_GUEST_ID);
  if (!guestId) {
    localStorage.setItem(BXL_GUEST_ID, generateUUID());
  }
};


export const getContentLinkByUrl = (url: string = window.location.href) => {
  return  url.trim().replace(/index.html/gi, "content.json") +
     `?t=${new Date().getTime()}`
};

export const getChildrenById = (id: string, content: Content)  => {
  return content[id]?.childrens?.map((cid) => content[cid]);
};

export function getDescendants(
  id: string,
  content: Content,
  descendants: INode[] = []
) {
  const component = content[id];
  if (!component) {
    return descendants;
  }
  descendants.push(component);
  component.childrens?.forEach((cid) =>
    getDescendants(cid, content, descendants)
  );
  return descendants;
};


interface UpdateINode<T extends string> extends INode{
  render: T
}

export function geNeedUpdatetDescendants<T extends string>(id: string, content: Content, keys: T[] ): UpdateINode<T>[] {
  const descendants = getDescendants(id, content);
  const keyMap = keys.reduce((init, item) => {
    init[item] = item;
    return init
  }, {} as Record<T, T>)
  return descendants.reduce((init, item) => {
    if (keyMap.hasOwnProperty(item.render)) {
      init.push(item as UpdateINode<T>);
    }
    return init;
  }, [] as UpdateINode<T>[]);
}


export function pxToRem(size: number, fontSize = 75) {
  return `${size / fontSize}rem`;
}


export const getProductListHeightByConfig = (
  productRow?: NonNullable<ProductListProps["config"]>["productRow"],
  quantity?: number
): CSSProperties["height"] => {
  if (typeof quantity !== "number") {
    return;
  }
  switch (productRow) {
    case "row":
      return pxToRem(467 * (quantity > 1 ? 1 : quantity) + 25);
    case 1:
      return pxToRem(quantity * 306 + 25);
    case 2:
      return pxToRem(Math.ceil(quantity / 2) * 568 + 25);
    case 3:
      return pxToRem(Math.ceil(quantity / 3) * 412 + 25);
  }
};

export const getCombinationListHeigt = (quantity: number): CSSProperties["height"] => {
    if (!quantity) {
      return;
    }
    return pxToRem(quantity * 426);
  }


export function memoizeOne<T extends (type?: string | number, quantity?: string | number) => any>(handle: T): T {
  const cache: Record<string, ReturnType<T>> = {}
  return ((type, quantity) => {
    const key = `${type}_${quantity}`
    if(cache[key] !== undefined) {
      return cache[key]
    }
    cache[key] =  handle(type, quantity)
    return cache[key]
  }) as T
}