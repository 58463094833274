export const BXL_META_PREFIX = 'BXL_META_'

export const PAGE_LOCATION = 'H5_LG_P'

export const BXL_USER_ID = 'BXL_USER_ID'

export const BXL_USER_KEY = 'BXL_USER_KEY'

export const BASE_PATH = 'https://baleen-cdn-g.bybieyang.com'

export const BXL_META_device_id = "BXL_META_device_id";

export const BXL_GUEST_ID = "BXL_GUEST_ID";

export const DOWNLOAD_PAGE_PREFIX = 'https://baleen-cdn-g.bybieyang.com/static/html/appEntry/index.html'

export const BYH5 = 'https://baleen-cdn-g.bybieyang.com/static/html/byh5/index.html'

export const BYSTYLEUS_BASE_PATH = "https://dapper.bybieyang.com";

export const DOWNLOAD_LINK = 'https://bybieyang-new.datasink.sensorsdata.cn/r/fd'

export const renderToComponent: Record<string, string> = {
  ImageBox: "img",
  MixedBlocks: "mixedBlock",
  CustomTitle: "customTitle",
  NoticeBar: "noticebar",
  Block: "block",
  CombinationList: "combination",
  ProductList: "product",
  VideoBox: "video",
  SearchBar: "searchBar",
};

export const BASE_HOST: Record<string, string> = {
  grotto: "https://santa-grotto.santasmartnetwork.com",
  laas: "https://santa-laas.santasmartnetwork.com",
  ewe: "https://santa-ewe.santasmartnetwork.com",
};
