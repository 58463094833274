import { useMemo, useEffect, useRef } from 'react'
import PromiseConcurrencyQueues, {
  type HandleCallback,
  type PromiseQueuesOptions,
} from "../utils/promise-concurrency-queues";

export default function usePromiseConcurrencyQueues<T = any>(
  callback: HandleCallback<T>,
  options?: PromiseQueuesOptions
) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queues = useMemo(() => new PromiseConcurrencyQueues<T>(options), []);

  useEffect(() => {
    queues.subscribe((param) => callbackRef.current?.(param));
    queues.run()
    return () => {
      queues.destory();
    };
  }, [queues]);
  return queues;
}