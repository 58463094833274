import { LazyExoticComponent, ComponentType, Suspense } from "react";
export function SuspenseComponent<T>(
  Component: LazyExoticComponent<ComponentType<any>>
) {
  return (props: T) => {
    return (
      <Suspense fallback={null}>
        <Component {...props}></Component>
      </Suspense>
    );
  };
}
