import http, { baseURL } from "../utils/http";
import type { Content } from 'bxl-legocore'
import { DOWNLOAD_LINK } from '../constant'
import { DeeplinkParser, isMiniProgram, bxlNavigation } from "bxl-miniParse";
import Toast from '../components/Toast';
import utils from "bxl-utils";
import type { AxiosRequestConfig } from 'axios'

export const getContentJsonByUrl = (url: string, optons?: AxiosRequestConfig) =>
  http.get<Content>(url, {
    params: {
      t: Date.now(),
    },
    ...optons,
    baseURL: undefined
  });

export const getAppConfig = () =>
  http.get("/api/v1/appconfig", );

export const getProductsBySearch = (queries: Record<string, any> = {}) =>
  http.post(
    "/api/v3/discover",
    {
      q: utils.uri.stringify({ ...queries, aggr: false }),
    },
    {
      headers: {
        "Content-Type": "application/x-protobuf-json",
      },
      baseURL,
    }
  );

export const getProductsByIds = (productIds: string[]) => {
  return http.post(
    "/api/v3/discover/recommendation",
    {
      productIds,
    },
    {
      baseURL
    }
  );
};

export const getCombinationByIds = (ids: string[]) =>
  http.post(
    `/api/v1/board/combination-products`,
    {
      combinationIds: ids,
    },
    {
      baseURL: `https://tapestry-prod.bybieyang.com`,
    }
  );

export const getCart = () =>
  http.get(`/api/v2/shoppingcart/cart-meta-info`);


export const getCoupon = (link: string) => {
  http
    .post(link, { baseURL: undefined })
    .then((res) => {
      Toast.info(res.data?.toast?.text?.text, 1);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const registerDeeplinkParser = (isLogin: () => string | null) => {
  DeeplinkParser.registerHandle(
    "uia",
    DeeplinkParser.uriAccecpthandler,
    (params: Record<string, any>) => {
      if (params.login) {
        if (isLogin()) {
          getCoupon(params.link);
        } else {
          isMiniProgram().then((res: any) => {
            if (res) {
              bxlNavigation.navigateTo({
                url: "/pages/login/login",
              });
            } else {
              window.location.href = DOWNLOAD_LINK;
            }
          });
        }
      } else {
        getCoupon(params.link);
      }
    }
  );
};