import utils from "bxl-utils";
import { INode } from 'bxl-legocore'
import http from './http'
import wx from 'wx' //微信分享
import { BXL_USER_ID } from "../constant";

export function shareToWechat (data: Record<string, any>) {
  http.get('https://5thave-prod.bybieyang.com/api/v1/wechat-jsticket?url=' + encodeURIComponent(window.location.href.split('#')[0]))
    .then((config) => {
      let localConfig = config['data'];
      // localConfig['debug'] = true
      localConfig['jsApiList'] = ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'onMenuShareTimeline'];
      // config['data']['jsApiList'] = ['onMenuShareAppMessage', 'onMenuShareTimeline'];
      wx.config(localConfig);
      let link = data.link || window.location.href;
      let icon = data.icon || 'https://haul-cdn-g.bybieyang.com/by-download-bar/img/icon.png';
      let title = data.title;
      let description = (data.description || data.title) + ' 别样海外购|总有好货在远方';
      wx.ready(() => {
        wx.updateAppMessageShareData({
          title: title,
          link: link,
          desc: description,
          imgUrl: icon,
          success: function () {
          }
        });
        wx.updateTimelineShareData({
          title: title,
          link: link,
          desc: description,
          imgUrl: icon,
          success: function () {
          }
        });
        wx.onMenuShareTimeline({
          title: title, // 分享标题
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: icon, // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          },
        })
        wx.onMenuShareAppMessage({
          title: title, // 分享标题
          desc: description, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: icon, // 分享图标
          success: function () {
          }
        });
      });
    })
}

export const share = (config: INode) => {
  if (utils.platform.isWechatBrowser()) {
    const data = utils.uri.parseUrl(window.location.href);
    const params = {
        utm_source: 'share_h5',
        utm_campaign: 'lego',
        utm_term: config?.pageId,
        utm_content: localStorage.getItem(BXL_USER_ID) || '',
        ...data.query
    }
    const link = data.url + '?' + utils.uri.stringify(params);
    shareToWechat({
      icon: config?.shareImg,
      title: config?.shareTit,
      description: config?.shareDes,
      link: link
    });
  }
};
