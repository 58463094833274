import { FC } from 'react'
import { Helmet } from "react-helmet";

interface SEProps {
  title?: string;
  keywords?: string;
  description?: string
}

const SEO: FC<SEProps> = ({ title, keywords, description }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {keywords && <meta name="keywords" content={keywords} />}
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};

SEO.displayName = "SEO";

export default SEO;
