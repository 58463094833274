import { useEffect, useState, useRef } from "react";
import { Content } from "bxl-legocore";
import axios, { type Canceler } from "axios";
import { getContentJsonByUrl } from "../api";
import { getContentLinkByUrl } from "../utils";

export default function useContent(url: string = window.location.href) {
  const cancelTokenRef = useRef<Canceler | null>(null);
  const [content, setContent] = useState<Content | null>(null);
  const [pending, setPending] = useState(false)
  useEffect(() => {
    const link = getContentLinkByUrl(url);
    if (link) {
      cancelTokenRef.current?.()
      setPending(true);
      getContentJsonByUrl(link, {
        cancelToken: new axios.CancelToken(function executor(cancelHandler) {
          cancelTokenRef.current = cancelHandler;
        }),
      })
        .then(({ data }) => {
          setContent(data);
        })
        .finally(() => setPending(false));
    }
  }, [url]);
  return [content, pending] as const;
}

