import type { ImpressionEvent } from "bxl-legocore";
import { getProductSearchQuery } from "bxl-legocore/dist/utils";

import {
  getContentJsonByUrl,
  getProductsBySearch,
  getProductsByIds,
  getCombinationByIds,
} from "../api";

export const onIframeUpdate = (e: ImpressionEvent) => {
  const config = e.config;
  if (config.render === "Iframe" ) {
    const url = config.config?.url;
    if (url) {
     return getContentJsonByUrl(url.replace("index.html", "content.json"))
    }
  }
};

export const setIframeData = (e: ImpressionEvent, data: any) => {
  e.setState(data, true);
};

export const onProductUpdate = (e: ImpressionEvent) => {
  const config = e.config;
  if (config.render === "ProductList") {
    const { parseType } = config.config;
    if (parseType === "tag") {
      return getProductsBySearch(getProductSearchQuery(config))
    } else if (parseType === "id") {
      const ids = Array.from(new Set<string>(config.config?.ids || []));
      if (ids.length) {
        return getProductsByIds(ids)
      }
    }
  }
};

export const setProductData = (e: ImpressionEvent, data: any) => {
  const config = e.config;
  const { parseType, filterSellOut } = config.config;
    if (parseType === "tag") {
      const products = data.products.reduce((init: any[], item: any) => {
               if (item?.type === "PRODUCT") {
                 const pd = item.searchProduct;
                 if (filterSellOut) {
                   pd.product.inventoryStatus === "AVAILABLE" && init.push(pd);
                 } else {
                   init.push(pd);
                 }
               }
               return init;
             }, [])
       e.setState((pre) => ({
         [config.id]: {
           ...pre[config.id],
           config: {
             ...pre[config.id].config,
             products,
             quantity: products.length
           },
         },
       }));
    } else if (parseType === "id") {
      const products = filterSellOut
        ? data.filter((pd: any) => pd.product.inventoryStatus === "AVAILABLE")
        : data;
      e.setState((pre) => ({
        [config.id]: {
          ...pre[config.id],
          config: {
            ...pre[config.id]?.config,
            products,
            quantity: products.length,
          },
        },
      }));
    }
  }

export const onCombinationListUpdate = (e: ImpressionEvent) => {
  const config = e.config;
  if (config.render === "CombinationList") {
    const ids = Array.from(new Set<string>(config.config?.ids || []));
    if (ids?.length) {
      return getCombinationByIds(ids)
    }
  } 
};

export const setCombinationList = (e: ImpressionEvent, data: any) => {
  const config = e.config;
  e.setState((pre) => ({
    [config.id]: {
      ...pre[config.id],
      config: Object.assign({}, pre[config.id].config, {
        combinations: data.waterDrops || [],
        quantity: data.waterDrops?.length || 0
      }),
    },
  }));
};


export type UpdateKey = "ProductList" | "CombinationList" | "Iframe";

export const getFetcher = (render: UpdateKey) => {
  switch (render) {
    case "ProductList":
      return onProductUpdate;
    case "CombinationList":
      return onCombinationListUpdate;
    case "Iframe":
      return onIframeUpdate;
  }
};