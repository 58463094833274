import ReactDOM from "react-dom/client";
import ToastContainer from "./ToastContainer";
import "./index.scss";
const toastContainerDiv = document.createElement("div");
document.body.appendChild(toastContainerDiv);

// 这里返回的是 ToastContainer 组件引用
const getToastContainerRef = () => {
  // 将 <ToastContainer /> React 组件，渲染到 toastContainerDiv 中，并且返回了 <ToastContainer /> 的引用
  return ReactDOM.createRoot(toastContainerDiv).render(<ToastContainer />);
};

// 这里是 <ToastContainer /> 的引用
let toastContainer: any = getToastContainerRef();

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  info: (text: string, duration: number, isShowMask = false) =>
    toastContainer.pushToast({ type: "info", text, duration, isShowMask }),
};
