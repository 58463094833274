import bxlbridge from 'bxl-bridge';
import { isMiniProgram, MiniBridge } from "bxl-miniParse";
import { INode } from 'bxl-legocore';
import tracking from './tracking';
import Bxlutils from 'bxl-utils';
import {
  BXL_META_PREFIX,
  BXL_GUEST_ID,
  BXL_USER_ID,
  BXL_USER_KEY,
  PAGE_LOCATION,
} from "../constant";
/**
 *  控制native 顶部bar是否显示 以及配色的控制
 *      hideHeader: 是否隐藏headerBar
 *      headerBackgroundColor: 背景颜色
 *      headerTitle: 标题内容
 *      titleTextColor: 标题栏颜色
 *      statusBarColor: 状态栏颜色
 *      shareIconShow: 是否隐藏右侧分享按钮
 *      hookShareAction: 是否拦截分享。 如果hook的话， 可以在用户做分享的时候，吊起showShareDialog， 然后通过showAction注入share信息
 *      fitSystemWindow: 是否延伸到状态栏
 */
export const headerConfig = (msg: Record<string, any>) => {
  bxlbridge.invoke('onHeaderConfig', JSON.stringify(msg), (result: any) => {
    try {
      console.log(result);
    } catch (e) {
      console.log(e, result);
    }
  });
};
/**
 * msg = {
        stitle: '潮鞋裂变',
        sdescription: '潮鞋裂变, 通常也是副标题',
        slink: 'https://baleen-dev-cdn.bybieyang.com/static/html/luckDraw/index.html',
        simage: 'https://baleen-dev-cdn.bybieyang.com/static/html/luckDraw/static/media/tide_product_img.ddd24a2a.png',
        stype: 'image',          // 分享类型 消息 link | 图片 image | 小程序  miniwx
        spageid: 'luckDraw',     // 查埋点
        spath: '/pages/',        // 小程序打开路径
        smediatype: 6            // 分享渠道，微信朋友圈，微信会话，微博等
    }
 * 
 */
export function shareAction(msg: Record<string, any> = {}) {
  bxlbridge.invoke("shareAction", JSON.stringify(msg), (result: any) => {
    try {
      console.log(result);
    } catch (e) {
      console.error(e, result);
    }
  });
}
/**
 * 等用户选择分享渠道以后，app会通过这个方法通知到h5， 具体选择的渠道
 *
 * 这里应该根据不同渠道， 设置不同的分享数据， 然后调起分享
 */
export function shareFromNative(msg: Record<string, any>) {
  bxlbridge.register("shareFromNative", (result: any) => {
    let sharemsg = {
      ...msg,
      smediatype: +result,
      sdescription: +result === 2 ? msg.stitle : msg.sdescription,
      sdesc: +result === 2 ? msg.stitle : msg.sdesc,
    };
    shareAction(sharemsg);
    tracking.send({
      webPageShare: {
        pageName: "H5_LG_P",
        pageId: "lego_" + msg.spageid,
        url: window.location.href,
        shareType: +result,
      },
    });
  });
}
export const getMetaInfo = () => {
  bxlbridge.invoke("getMetaInfo", {}, (result: any) => {
    // 通过 bridge 获取真正的用户数据
    result = bxlbridge.parseBridgeParams(result);
    try {
      Object.keys(result).forEach((key) => {
        if (typeof Storage !== void 0) {
          localStorage.setItem(`${BXL_META_PREFIX}${key}`, result[key]);
        }
      });
    } catch (e) {
      console.error(e, result);
    }
  });
};


export function initMiniProgram () {
  isMiniProgram().then((res: Record<string, any>) => {
  if (res) {
    MiniBridge.on();
    MiniBridge.invoke("getSession", {}, (res: Record<string, any>) => {
      localStorage.setItem(BXL_USER_ID, res.userId);
      localStorage.setItem(BXL_USER_KEY, res.userKey);
      localStorage.setItem(BXL_GUEST_ID, res.guestId);
    });
    MiniBridge.register("onMiniLogin", (res: Record<string, any>) => {
      const { userId, userKey, guestId } = res;
      localStorage.setItem(BXL_USER_ID, userId);
      localStorage.setItem(BXL_USER_KEY, userKey);
      localStorage.setItem(BXL_GUEST_ID, guestId);
    });
  }
});
}


export const initHeaderInApp = (config: Partial<INode> = {}) => {
  headerConfig({
    shareIconShow: true,
    hideHeader: false,
    hookShareAction: true,
    statusBarColor: "#ffffff",
    fitSystemWindow: false,
    statusBarStyle: 0,
    headerTitle: config.title,
  });
  /*
   * ### 在分享后重新设置header，避免样式变形
   */
  const data = Bxlutils.uri.parseUrl(window.location.href);
  const params = {
    utm_source: 'share_app',
    utm_campaign: 'lego',
    utm_term: config.pageId,
    utm_content: localStorage.getItem(BXL_USER_ID) || '',
    ...data.query
  }
  const link = data.url + '?' + Bxlutils.uri.stringify(params);
  shareFromNative({
    stitle: config.shareTit,
    sdescription: config.shareDes,
    sdesc: config.shareDes,
    slink: link,
    simage: config.shareImg,
    fitSystemWindow: false,
    stype: "link",
    spageid: config.pageId,
  });
};


export const initAppSetting = (config: Partial<INode>) => {
  initHeaderInApp(config);
  bxlbridge.register("viewWillAppear", () => {
    initHeaderInApp(config);
  });
  bxlbridge.invoke(
    "noticePageName",
    JSON.stringify({
      pageName: PAGE_LOCATION,
    })
  );
};