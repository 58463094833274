import { useEffect, useState } from "react";
import { BXL_USER_ID, BXL_USER_KEY } from "../constant";
import { getCart } from "../api";

interface CartInfo {
  count?: number;
}

export default function useCart(isGetConfig: boolean = false) {
  const [cart, setCart] = useState<CartInfo>({});
  useEffect(() => {
    let userId = localStorage.getItem(BXL_USER_ID);
    let userKey = localStorage.getItem(BXL_USER_KEY);
    isGetConfig &&
      userId &&
      userKey &&
      getCart().then(({ data }) => {
        setCart({
          count: data.itemsQuantity,
        });
      });
  }, [isGetConfig]);
  return cart;
}
