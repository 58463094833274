export interface PromiseQueuesOptions {
  threshold?: number;
}
interface ResponseType<T, K> {
  data: T | null;
  error: Error | null;
  event: K
}

export type HandleCallback<T> = (responseList: Awaited<T>[]) => any;

type ReturnPromise<T> = () => Promise<T>

enum PoolStatus {
  RUNNINT,
  WAITING,
}

class Pool {
  public maxSize: number = 5;
  private _runningSize: number = 0;
  constructor(maxSize: number) {
    isNumber(maxSize) && (this.maxSize = maxSize);
  }
  public get state(): PoolStatus {
    return this._runningSize >= this.maxSize
      ? PoolStatus.RUNNINT
      : PoolStatus.WAITING;
  }

  public get idleSize(): number {
    return this.maxSize - this._runningSize;
  }

  public add(count: number = 1) {
    if (isNumber(count)) {
      const latestRunningSize = this._runningSize + count;
      if (latestRunningSize <= this.maxSize) {
        this._runningSize = latestRunningSize;
      } else {
        this._runningSize = this.maxSize;
      }
    }
  }

  public remove(count: number = 1) {
    if (isNumber(count)) {
      const latestRunningSize = this._runningSize - count;
      if (latestRunningSize <= 0) {
        this._runningSize = 0;
      } else {
        this._runningSize = latestRunningSize;
      }
    }
  }

  public clear() {
    this._runningSize = 0;
  }

  public static state = PoolStatus;
}

export default class PromiseConcurrencyQueues<T = any> {
  private threshold: number;
  private _queues: ReturnPromise<T>[];
  private _handlers: HandleCallback<T>[];
  private _pool: Pool;
  public constructor(options: PromiseQueuesOptions = {}) {
    const { threshold } = options;
    this.threshold = isNumber(threshold) ? threshold : 5;
    this._queues = [];
    this._handlers = [];
    this._pool = new Pool(this.threshold);
  }

  public get size(): number {
    return this._queues.length;
  }

  public push(...params: ReturnPromise<T>[]) {
    const queues = params.filter((item) => isFunction(item));
    const pushCount = this._queues.push(...queues);
    this.run();
    return pushCount;
  }

  public pop(threshold: number = this.threshold) {
    const promiseQueuesWithThreshold = this._queues.slice(0, threshold);
    this._queues = this._queues.slice(threshold);
    return promiseQueuesWithThreshold;
  }

  public subscribe(callback: HandleCallback<T>) {
    isFunction(callback) && this._handlers.push(callback);
  }

  public unsubscribe(callback: HandleCallback<T>) {
    this._handlers = this._handlers.filter((handler) => handler !== callback);
  }

  public async run() {
    if (this._pool.state === PoolStatus.WAITING && this.size > 0) {
      const idleSize = this._pool.idleSize;
      const runList = this.pop(idleSize);
      this._pool.add(runList.length);
      try {
        const response = await Promise.all(runList.map((item) => item()));
        this._handlers.forEach((handler) => handler(response));
      } catch (err) {
        console.error(err);
      }
      this._pool.remove(runList.length);
      this.run();
    }
  }

  clear() {
    this._queues = [];
  }

  destory() {
    this._handlers = [];
    this._queues = [];
  }
}

function isNumber(param: unknown): param is number {
  return typeof param === "number" && !isNaN(param);
}

function isFunction(param: unknown): param is (...params: any) => any {
  return typeof param === "function";
}


type HandlerType<T> = (...params: any) => (Promise<T> | undefined)


export function createPromiseWithoutReject<T = any, K = any>(
  handler: HandlerType<T>,
  event: K
): ReturnPromise<ResponseType<T, K>>
  {
  return () =>
    new Promise(async (resolve) => {
      try {
        const data = await handler(event);
        resolve({ data: data || null, error: null, event });
      } catch (error) {
        resolve({ data: null, error: error as Error, event });
      }
    });
}

