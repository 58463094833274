import { lazy } from 'react'
import { withImpression } from 'bxl-legocore'
import {
  Image,
  ImageBox,
  CustomTitle,
  TextBox,
  Block,
  SearchBar,
  NoticeBar,
  MixedBlocks,
  Conference,
  ProductList,
  AnchorTabs,
  CombinationList,
  Root,
  type ProductListProps,
  type CombinationListProps
} from "lego-components";
import { isBieyangApp } from '../utils'
import { SuspenseComponent } from "../utils/lazy";
import {
  getProductListHeightByConfig,
  getCombinationListHeigt,
} from "../utils";

const VideoBox = lazy(() => import('../components/videoBoxPlaceholder'))
export { zhCN as locale } from "lego-components/dist/locale";

const baseComponents = {
  Image,
  ImageBox,
  CustomTitle,
  TextBox,
  Block,
  SearchBar,
  NoticeBar,
  MixedBlocks,
  Conference,
  div: Root,
  Root,
  AnchorTabs: withImpression({
    offset: 200,
  })(AnchorTabs),
  VideoBox: SuspenseComponent(VideoBox),
  ProductList: withImpression({
    offset: 200,
    dynamic: true,
    height(node) {
      /**
       * todo 
       *  缓存计算结果
       */
      const target = node as ProductListProps;
      return getProductListHeightByConfig(target.config?.productRow, target.config?.quantity);
    }
  })(ProductList),
};

export const components = isBieyangApp
  ? {
      ...baseComponents,
      CombinationList: withImpression({
        offset: 200,
        dynamic: true,
        height(node) {
          /**
           * todo
           *  缓存计算结果
           */
          const target = node as CombinationListProps;
          return getCombinationListHeigt(target.config?.quantity);
        }
      })(CombinationList),
    }
  : baseComponents;


export type ComponentTypes = keyof typeof baseComponents |
  "Iframe" |
  "CombinationList";

  