import axios from 'axios'
import { BXL_USER_ID, BXL_USER_KEY, BASE_HOST } from "../constant";
import { platform } from "../constant/platform";
import bxlutils from "bxl-utils";
const channel = window.location.host.split(".")[0] as string;

export const baseURL = BASE_HOST[channel]
      ? BASE_HOST[channel]
      : "https://5thave-prod.bieyangapp.com"

const http = axios.create({
  headers: {
    "X-BXL-User-Agent": `Bieyang H5WB/${platform} 0.1.0 (${bxlutils.platform.getOS()} ${bxlutils.platform.getVersion()}; UID )${channel}`,
  },
  baseURL: "https://5thave-prod.bieyangapp.com",
});

function getAuth() {
  let userKey = "", userId = "";
  return () => {
    if(!userKey || !userId) {
      userKey = localStorage.getItem(BXL_USER_KEY) || '';
      userId = localStorage.getItem(BXL_USER_ID) || '';
    }
    return {
      "X-Session-Key": userKey,
      "X-Session-User": userId,
    };
  }
}

const getHeaders = getAuth();

http.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      ...getHeaders(),
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;