
import React, { Component } from 'react'
import ToastItem from './ToastItem'
const getUuid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
interface IToastItem {
  id: string
  type: string
  text?: string
  duration: number
  isShowMask: boolean
}
interface IInitState {
  isShowMask: boolean
  toastList: IToastItem[]
}
class ToastContainer extends Component<{}, IInitState>{
  readonly state: IInitState = {
    isShowMask: false, // 当前 mask 是否显示
    toastList: [] // 当前 Toast item 列表
  }

  // 将新的 toast push 到 toastContainer 中
  public pushToast = (toastProps: Record<string, any>) => {
    const { type, text, duration, isShowMask } = toastProps;
    const { toastList } = this.state;
    toastList.push({
      id: getUuid(),
      type,
      text,
      duration: duration || 1.5,
      isShowMask: isShowMask || false
    });
    this.setState({
      toastList,
      isShowMask
    });
  }

  popToast = (id: string, isShowMask: boolean) => {
    const { toastList } = this.state;
    const newList = toastList.filter(item => item.id !== id);
    this.setState({
      toastList: newList,
    });
    // 该 toast item 是否为 toastList 中 duration 最长的 item
    let isTheMaxDuration = true;
    // 该 toast item 的 duration
    const targetDuration = toastList.find(item => item.id === id)?.duration;
    // 遍历 toastList 检查是否为最长 duration
    toastList.forEach(item => {
      if (item.isShowMask && targetDuration && item.duration > targetDuration) {
        isTheMaxDuration = false
      }
      return null;
    });

    // 隐藏 mask
    if (isShowMask && isTheMaxDuration) {
      this.setState({
        isShowMask: false
      })
    }
  }
  render() {
    const { toastList, isShowMask } = this.state;
    return (
      <div className="toast-container">
        {isShowMask && <div className="mask" />}
        <div className="toast-wrap">
          {toastList.reverse().map((item) => (
            <ToastItem {...item} key={item.id} onClose={this.popToast} />
          ))}
        </div>
      </div>
    );
  }
}
export default ToastContainer