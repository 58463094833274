import { useEffect, useState } from 'react'
import { getAppConfig } from '../api'

export default function useAppConfig(isGetConfig: boolean = false) {
  const [appConfig, setAppConfig] = useState({
    priceWithCurrencyText: '' 
  }); 
  useEffect(() => {
    isGetConfig && getAppConfig().then(({ data }) => {
      const priceWithCurrencyText = data?.newcomerConfig?.newcomerBenefits?.priceWithCurrencyText
      priceWithCurrencyText && setAppConfig({
        priceWithCurrencyText
      });
    });
  }, [isGetConfig]);
  return appConfig;
}